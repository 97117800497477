const ConsumersIcon = (props) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="24" height="24" viewBox="0 0 24 24"
		fill="currentColor"
		stroke="none"
		{...props}
	>
		<path d="M12 6C13.11 6 14 5.1 14 4C14 3.62 13.9 3.27 13.71 2.97L12 0L10.29 2.97C10.1 3.27 10 3.62 10 4C10 4.53043 10.2107 5.03914 10.5858 5.41421C10.9609 5.78929 11.4696 6 12 6ZM16.6 16L15.53 14.92L14.45 16C13.15 17.29 10.87 17.3 9.56 16L8.5 14.92L7.4 16C6.75 16.64 5.88 17 4.96 17C4.23 17 3.56 16.77 3 16.39V21C3 21.2652 3.10536 21.5196 3.29289 21.7071C3.48043 21.8946 3.73478 22 4 22H20C20.2652 22 20.5196 21.8946 20.7071 21.7071C20.8946 21.5196 21 21.2652 21 21V16.39C20.44 16.77 19.77 17 19.04 17C18.12 17 17.25 16.64 16.6 16ZM18 9H13V7H11V9H6C5.20435 9 4.44129 9.31607 3.87868 9.87868C3.31607 10.4413 3 11.2044 3 12V13.54C3 14.62 3.88 15.5 4.96 15.5C5.5 15.5 6 15.3 6.34 14.93L8.5 12.8L10.61 14.93C11.35 15.67 12.64 15.67 13.38 14.93L15.5 12.8L17.65 14.93C18 15.3 18.5 15.5 19.03 15.5C20.11 15.5 21 14.62 21 13.54V12C21 11.2044 20.6839 10.4413 20.1213 9.87868C19.5587 9.31607 18.7956 9 18 9Z" />
	</svg>
)

export default ConsumersIcon