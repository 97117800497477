const ConsumerIcon = (props) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="24" height="24" viewBox="0 0 24 24"
		fill="currentColor"
		stroke="none"
		{...props}
	>
		<path fillRule="evenodd" clipRule="evenodd" d="M11 4.26102L6 11.1305V12.6025L18 8.67712V7.20509C18 5.2424 12 3.27968 11 4.26102ZM18 11.6212V14.0746L6 18V15.5466L18 11.6212ZM12 9C13.1046 9 14 8.10457 14 7C14 5.89543 13.1046 5 12 5C10.8954 5 10 5.89543 10 7C10 8.10457 10.8954 9 12 9Z" />
		<path d="M6 11.1305L5.59574 10.8363L5.5 10.9678V11.1305H6ZM11 4.26102L10.6498 3.90415L10.6202 3.93322L10.5957 3.96678L11 4.26102ZM6 12.6025H5.5V13.2922L6.15545 13.0778L6 12.6025ZM18 8.67712L18.1555 9.15234L18.5 9.03963V8.67712H18ZM18 14.0746L18.1555 14.5498L18.5 14.4371V14.0746H18ZM18 11.6212H18.5V10.9316L17.8445 11.146L18 11.6212ZM6 18H5.5V18.6896L6.15545 18.4752L6 18ZM6 15.5466L5.84455 15.0714L5.5 15.1841V15.5466H6ZM6.40426 11.4248L11.4043 4.55526L10.5957 3.96678L5.59574 10.8363L6.40426 11.4248ZM6.5 12.6025V11.1305H5.5V12.6025H6.5ZM17.8445 8.2019L5.84455 12.1273L6.15545 13.0778L18.1555 9.15234L17.8445 8.2019ZM17.5 7.20509V8.67712H18.5V7.20509H17.5ZM11.3502 4.61789C11.3631 4.60524 11.44 4.5509 11.6573 4.52043C11.8622 4.49171 12.1342 4.49239 12.4614 4.52808C13.114 4.59924 13.9162 4.80159 14.6984 5.09683C15.4822 5.39265 16.2153 5.77022 16.742 6.17539C17.2973 6.60251 17.5 6.96294 17.5 7.20509H18.5C18.5 6.46589 17.9527 5.84497 17.3517 5.38274C16.7222 4.89856 15.8928 4.47878 15.0516 4.16125C14.2088 3.84314 13.3235 3.61615 12.5698 3.53397C12.1939 3.49298 11.8331 3.486 11.5185 3.53011C11.2163 3.57247 10.8869 3.67146 10.6498 3.90415L11.3502 4.61789ZM18.5 14.0746V11.6212H17.5V14.0746H18.5ZM6.15545 18.4752L18.1555 14.5498L17.8445 13.5994L5.84455 17.5248L6.15545 18.4752ZM5.5 15.5466V18H6.5V15.5466H5.5ZM17.8445 11.146L5.84455 15.0714L6.15545 16.0218L18.1555 12.0964L17.8445 11.146ZM13.5 7C13.5 7.82842 12.8284 8.5 12 8.5V9.5C13.3807 9.5 14.5 8.38071 14.5 7H13.5ZM12 5.5C12.8284 5.5 13.5 6.17157 13.5 7H14.5C14.5 5.61928 13.3807 4.5 12 4.5V5.5ZM10.5 7C10.5 6.17157 11.1716 5.5 12 5.5V4.5C10.6193 4.5 9.5 5.61928 9.5 7H10.5ZM12 8.5C11.1716 8.5 10.5 7.82842 10.5 7H9.5C9.5 8.38071 10.6193 9.5 12 9.5V8.5ZM18.5 11.6212V8.67712H17.5V11.6212H18.5ZM6.5 15.5466V12.6025H5.5V15.5466H6.5Z" />
	</svg>
)

export default ConsumerIcon