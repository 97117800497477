const DirectionRightIcon = (props) => (
	<svg 
		xmlns="http://www.w3.org/2000/svg" 
		width="14" height="14" viewBox="0 0 14 14"
		fill="none" stroke="currentColor"
		{...props} 
	>
		<path d="M3 7H9M9 7L5 3M9 7L5 11" strokeWidth="2"/>
	</svg>
)

export default DirectionRightIcon