const MessagesIcon = (props) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="24" height="24" viewBox="0 0 24 24"
		fill="currentColor"
		stroke="none"
		{...props}
	>
		<path d="M14.2727 19.3637C12.9708 19.3637 11.7221 18.8465 10.8015 17.9258C9.88085 17.0052 9.36365 15.7565 9.36365 14.4546C9.36365 11.1818 14.2727 5.65912 14.2727 5.65912C14.2727 5.65912 19.1818 11.1818 19.1818 14.4546C19.1818 15.7565 18.6646 17.0052 17.744 17.9258C16.8234 18.8465 15.5747 19.3637 14.2727 19.3637Z"/>
		<path d="M7.90907 11.1818C7.25809 11.1818 6.63377 10.9232 6.17345 10.4629C5.71313 10.0026 5.45453 9.37824 5.45453 8.72726C5.45453 7.09089 7.90907 4.32953 7.90907 4.32953C7.90907 4.32953 10.3636 7.09089 10.3636 8.72726C10.3636 9.37824 10.105 10.0026 9.6447 10.4629C9.18438 10.9232 8.56006 11.1818 7.90907 11.1818Z"/>
	</svg>
)

export default MessagesIcon