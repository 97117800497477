const BucketIcon = (props) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="24" height="24" viewBox="0 0 24 24"
		fill="currentColor"
		stroke="none"
		{...props}
	>
		<path d="M15.1 1.81L12.27 4.64C11.5 5.42 11.5 6.69 12.27 7.47L13.68 8.88L9.13001 13.43L6.31001 10.6L4.89001 12C-0.0599859 17 3.50001 20.5 3.50001 20.5C3.50001 20.5 7.00001 24 12 19.09L13.41 17.68L10.61 14.88L15.15 10.34L16.54 11.73C17.32 12.5 18.59 12.5 19.37 11.73L22.2 8.9L15.1 1.81ZM17.93 10.28L16.55 8.9L15.11 7.46L13.71 6.06L15.12 4.65L19.35 8.88L17.93 10.28Z" />
	</svg>
)

export default BucketIcon