const KvEntryIcon = (props) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="24" height="24" viewBox="0 0 24 24"
		fill="currentColor"
		stroke="none"
		{...props}
	>
		<path d="M7 14C5.9 14 5 13.1 5 12C5 10.9 5.9 10 7 10C8.1 10 9 10.9 9 12C9 13.1 8.1 14 7 14ZM12.6 10C11.8 7.7 9.6 6 7 6C3.7 6 1 8.7 1 12C1 15.3 3.7 18 7 18C9.6 18 11.8 16.3 12.6 14H16V18H20V14H23V10H12.6Z" />
	</svg>
)

export default KvEntryIcon